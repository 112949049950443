import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { selectHasPermissionForObjectById } from "../authorization.selectors";
import { Permission } from "../core.state";
import { PermissionProvider } from "../abstractions/permission-provider";

@Injectable()
export class PermissionProviderService implements PermissionProvider {

    public constructor(
        private readonly store: Store) {
    }

    public isPeopleGroupActionAuthorizedByObjectId(objectId: string | null, requiredPermission: Permission): Observable<boolean | null>{
        return this.store.select(selectHasPermissionForObjectById(objectId, requiredPermission));
    }
}
