import { InjectionToken } from "@angular/core";

export const WINDOW_WRAPPER_INJECT_TOKEN
    = new InjectionToken<Window>('GlobalWindow');

export const LOCAL_STORAGE_INJECT_TOKEN
    = new InjectionToken<Storage>('LocalStorage');

export const SESSION_STORAGE_INJECT_TOKEN
    = new InjectionToken<Storage>('SessionStorage');
