import { Provider } from '@angular/core';
import {
    LOCAL_STORAGE_INJECT_TOKEN,
    SESSION_STORAGE_INJECT_TOKEN,
    WINDOW_WRAPPER_INJECT_TOKEN,
} from './abstractions/browser-api';

// Here is a list of services placed into the file to be able to replace
// them at the build time in convinient way (by one replacement).

export const browserApiProviders: Provider[] = [
    { provide: WINDOW_WRAPPER_INJECT_TOKEN, useFactory: () => window },
    { provide: LOCAL_STORAGE_INJECT_TOKEN, useFactory: () => localStorage },
    { provide: SESSION_STORAGE_INJECT_TOKEN, useFactory: () => sessionStorage },
]
