import { Params } from '@angular/router';
import { validate } from 'uuid';

const uuidStringGuard = (input: unknown): input is string => {
  if (!input) {
    return false;
  }
  return typeof input === 'string' && validate(input);
};

export const getRouteParam = <T>(params: Params | undefined, name: string): T | null => {
  if (!params) {
    return null;
  }

  const paramValue: unknown = params[name];
  if (name === 'uuid' && !uuidStringGuard(paramValue)) {
    return null;
  }
  return <T>paramValue;
};
