import { DateTime, Interval } from 'luxon';
import { DateObjectUnits } from 'luxon/src/datetime';

/**
 * Set of commonly used functions and values, serving as an entry point for date/time calculations.
 * Must be pure and atomic as much as possible.
 * For more complex cases, consider creating services.
 * Luxon is used as the main, well-proven, and tested library, providing necessary abstractions.
 *
 * @todo Switch to temporal API when the polyfill is ready.
 */

/**
 * One day length in milliseconds count (default JS value)
 */
export const DayInMilliseconds = 86400000;

/**
 * Represents the maximum valid date by string value at backend format
 */
export const MAX_DATE_STRING = '9999-12-31';

/**
 * Normalize provided date and returns new instance
 * Drop hours, minutes, milliseconds
 * Use to avoid problems with comparison, calculations and formatting
 * @param date
 */
export const normalizeDateTime = (date: DateTime): DateTime => {
  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
  });
};

/**
 * construct luxon DateTime from date string
 * format should be presented as moment parser tokens, example: 'yyyy-MM-dd'
 * https://moment.github.io/luxon/#/parsing?id=table-of-tokens
 * Drops hours
 * @param date
 * @param format
 */
export const dateTimeFromFormat = (date: string, format: string): DateTime => {
  const formatted = DateTime.fromFormat(date, format);

  if (!formatted.isValid) {
    throw new Error(`Date string "${date}" can't be formatted to date with "${format}" format. Check your data.`);
  }

  return normalizeDateTime(formatted);
};

/**
 * Represents the maximum valid date, i.e. '9999-12-31'.
 */
export const MAX_DATE = dateTimeFromFormat(MAX_DATE_STRING, 'yyyy-MM-dd');

/**
 * Represents the minimum valid date, i.e. '1970-01-01'.
 */
export const MIN_DATE = dateTimeFromFormat('1970-01-01', 'yyyy-MM-dd');

/**
 * Represents the maximum valid Unix timestamp, equivalent to the date '9999-12-31'.
 */
export const MAX_DATE_TIMESTAMP = MAX_DATE.toMillis();

/**
 * Represents the minimum valid Unix timestamp, equivalent to the date '1970-01-01'.
 */
export const MIN_DATE_TIMESTAMP = MIN_DATE.toMillis();

export const ANYORG_RELEASE_DATE = dateTimeFromFormat('2024-04-18', 'yyyy-MM-dd');
export const ANYORG_RELEASE_DATETIME = ANYORG_RELEASE_DATE.toMillis();

/**
 * Represent all possible date types that usually handled together
 */
export type DateValue = Date | number | null;

/**
 * Type to use for frontend periods definitions
 * if start is null, we assume its equal to MIN_DATE
 * if end is null, we assume its equal to MAX_DATE
 */
export type DatePeriod = {
  start: DateValue;
  end: DateValue;
};

export const normalizeDate = (date: Date): Date => {
  const cleared = new Date(date);
  cleared.setHours(0, 0, 0, 0);

  return cleared;
};

/**
 * Constructs luxon DateTime from number, date or null
 * Drops hours of the day, works only with date
 * null is handled as MAX_DATE
 * @param date
 */
export const dateTimeFromDate = (date?: DateValue): DateTime => {
  if (date === null || typeof date === 'undefined') {
    return MAX_DATE;
  }

  const dateToFormat = date instanceof Date ? date : new Date(date);

  // we use DateTime.fromObject instead of DateTime.fromJsDate or DateTime.fromMillis to be sure that we have exact date no matter which local timezone we have
  return DateTime.fromObject({
    year: dateToFormat.getFullYear(),
    month: dateToFormat.getMonth() + 1,
    day: dateToFormat.getDate(),
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

/**
 * Constructs luxon Interval object from DatePeriod type
 * If any periods have null, handles respectfully to DatePeriod:
 * start === null => MIN_DATE
 * end === null => MAX_DATE
 * @param period
 */
export const intervalFromDatePeriod = (period: DatePeriod | undefined): Interval => {
  if(period === undefined) {
    throw new Error('Invalid date period provided to get interval');
  }
  const startDate = period.start ? dateTimeFromDate(period.start) : MIN_DATE;
  const endDate = period.end ? dateTimeFromDate(period.end) : MAX_DATE;

  if (endDate < startDate) {
    throw new Error(`Start date for the period should be less than end date.
    \nGiven start: "${startDate.toLocaleString()}, end: "${endDate.toLocaleString()}"`);
  }

  const result = Interval.fromDateTimes(startDate, endDate);

  if (!result.isValid) {
    throw new Error(`Can't create valid Interval for provided dates:
    ${result.invalidReason}:${result.invalidExplanation || ''}.
    Period start: ${period.start?.toString() || 'null'}, Period end: ${period.end?.toString() || 'null'}
    Interval start: "${startDate.toLocaleString()}, Interval end: "${endDate.toLocaleString()}"`);
  }

  return Interval.fromDateTimes(startDate, endDate);
};

/**
 * Check if the provided value is a maximum date.
 *
 * @param date The date or timestamp to check.
 * @returns Returns true if the date matches the maximum date value, otherwise false.
 */
export function isMaxDate(date: DateTime | number | Date): boolean {
  return isDatesEqual(date, MAX_DATE);
}

/**
 * Return object with date values for year, month and day
 * @param date
 */
export const getDateObj = (date: DateValue | DateTime): DateObjectUnits => {
  if (date instanceof Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  } else if (date instanceof DateTime) {
    return {
      year: date.year,
      month: date.month,
      day: date.day,
    };
  } else if (date === null) {
    return getDateObj(MAX_DATE);
  }

  return getDateObj(new Date(date));
};

/**
 * Compare different types of dates to check equality. Checks only for date value and don't use hours
 * Do not use comparison by Timestamp or Date, DateTime because they have different time zones
 * getDateObjCall only for testing purposes
 * @param a
 * @param b
 * @param getDateObjCall - option to provide spy for testing this function
 */
export function isDatesEqual(a: DateValue | DateTime, b: DateValue | DateTime, getDateObjCall = getDateObj): boolean {
  const aDateObj = getDateObjCall(a);
  const bDateObj = getDateObjCall(b);

  return aDateObj.year === bDateObj.year && aDateObj.month === bDateObj.month && aDateObj.day === bDateObj.day;
}

export const isPeriodValid = (period: DatePeriod): boolean => {
  if (period.end === null || period.start === null) {
    return true;
  }

  return period.end >= period.start;
};

/**
 * Return true in case if intervals shares any same date
 * If intervals are not crossed, return false
 * If intervals shares only edge day, return false
 * Example "01.01.2020-02.02.2020" and "02.02.2020-03.03.2020" returns false
 * Example "01.01.2020-03.03.2020" and "02.02.2020-03.03.2020" returns true
 * @param a
 * @param b
 */
export const isPeriodsOverlap = (a: DatePeriod, b: DatePeriod): boolean => {
  const aDateTime = intervalFromDatePeriod(a);
  const bDateTime = intervalFromDatePeriod(b);

  return aDateTime.overlaps(bDateTime);
};

/**
 * Return true in case if first period completely inside second period
 * If child period and parent period share an edge date, assume this edge is within
 * Example "02.02.2020-03.03.2020" and "01.01.2020-04.04.2020" returns true
 * Example "01.01.2020-02.02.2020" and "01.01.2020-03.03.2020" returns true
 * @param childPeriod
 * @param parentPeriod
 */
export const isPeriodWithin = (childPeriod: DatePeriod, parentPeriod: DatePeriod): boolean => {
  const childInterval = intervalFromDatePeriod(childPeriod);
  const parentInterval = intervalFromDatePeriod(parentPeriod);

  return parentInterval.engulfs(childInterval);
};

export const isRangeCoveredBySet = (childSet: DatePeriod[], target: DatePeriod): boolean => {
  // If childSet is empty, return false
  if (childSet.length === 0) {
    return false;
  }

  const intervals = childSet.map((period) => intervalFromDatePeriod(period));

  // Merge all intervals in the childSet
  const mergedIntervals = Interval.merge(intervals);
  
  const shiftIntervals: Interval[] = [];

  /* If there is no overlapping then the merged result will be more than one*/
  for (let i = 0; i < mergedIntervals.length; i++) {
    const currentInterval = mergedIntervals[i];
    const nextInterval = mergedIntervals[i + 1];
  
    if (currentInterval === undefined || nextInterval === undefined) {
     continue;
    }

    if (!nextInterval.isValid || !currentInterval.isValid ) {
      throw new Error('Invalid Internal between cildSets');
    }

    const currentEndDate = currentInterval.end;
    const nextStartDate = nextInterval.start;

    if (currentEndDate === null || nextStartDate === null) {
      throw new Error('Invalid current internal end date or next interval start date');
    }

    if (nextStartDate.diff(currentEndDate,'days').toObject().days === 1) 
    {
      const missing = Interval.fromDateTimes(currentEndDate,currentEndDate.plus({day:1}));
      shiftIntervals.push(missing);
    }
  }

  /* add the intervals with one day sifts in the existing intervals */
  shiftIntervals.forEach((m) => mergedIntervals.push(m));
  const mergedWithShifts = Interval.merge(mergedIntervals);

  // Convert the target range to an interval
  const targetInterval = intervalFromDatePeriod(target);

  // Check if the union of intervals engulfs the target interval
  return mergedWithShifts.map((interval) => interval.engulfs(targetInterval)).includes(true);
};

/**
 * Returns true if first date (a) after (more than) second date (b)
 * does not respect hours, checks only date
 * in case of null, uses MAX_DATE
 * @param a
 * @param b
 */
export const isDateAfter = (a: DateValue, b: DateValue): boolean => {
  const aDate = dateTimeFromDate(a);
  const bDate = dateTimeFromDate(b);

  return aDate > bDate;
};

/**
 * Returns true if first date (a) before (less than) second date (b)
 * does not respect hours, checks only date
 * in case of null, uses MAX_DATE
 * @param a
 * @param b
 */
export const isDateBefore = (a: DateValue, b: DateValue): boolean => {
  const aDate = dateTimeFromDate(a);
  const bDate = dateTimeFromDate(b);

  return aDate < bDate;
};

/**
 * Returns true if date is within date range
 * does not respect hours, checks only date
 * in case of null, uses MAX_DATE
 * @param dateRange
 * @param date
 */
export const isDateWithinRange = (dateRange: DatePeriod, date: DateValue): boolean => {
  const startDate = dateTimeFromDate(dateRange.start);
  const endDate = dateTimeFromDate(dateRange.end);
  const dateValue = dateTimeFromDate(date);

  return startDate <= dateValue && endDate >= dateValue ;
};
