import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export enum ObjectStates {
    future = 'future',
    past = 'past',
    active = 'active',
}

export type ObjectState = keyof typeof ObjectStates;

@Component({
    selector: 'aog-object-status-badge',
    templateUrl: './object-status-badge.component.html',
    styleUrls: ['./object-status-badge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectStatusBadgeComponent {
    @Input()
    public objectState!: ObjectState;
}
