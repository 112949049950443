/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { failGenericRequest } from '../core.actions';
import { HttpErrorEventDispatcherService } from '../services/http-error-event-dispatcher.service';
import { ErrorDetails, SerializableHttpErrorResponse } from '../abstractions/error-actions-handler';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

    public constructor(
        private readonly dispatcher: HttpErrorEventDispatcherService,
        private readonly store: Store,
    ) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse | undefined) => this.handleError(request, error)),
        );
    }

    private handleError(request: HttpRequest<unknown>, httpError: HttpErrorResponse | undefined): Observable<never> {
        const actions = this.dispatcher.getErrorHandler(request);
        const error = ErrorHandlingInterceptor.fromHttpErrorResponse(httpError);

        if (!!actions && actions.length > 0) {
            actions.forEach((action) => {
                this.store.dispatch(action(error));
            });
        } else {
            this.store.dispatch(failGenericRequest({ error }));
        }
        return NEVER;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static isErrorDetails(error: any): error is ErrorDetails {
        return error
            && error.detail
            && error.status
            && error.title
            && error.traceId
            && error.type;
    }

    public static fromHttpErrorResponse(error: HttpErrorResponse | undefined): SerializableHttpErrorResponse {
        // We remove headers here.
        // If it would be neccery in the future - read them here and convert to suitable object

        if (!error) {
            return {
                status: -1,
                statusText: "",
                error: null,
                url: "",
            }
        }

        const details = error.error;

        return {
          status: error.status,
          statusText: error.statusText,
          error: ErrorHandlingInterceptor.isErrorDetails(details)
            ? {
                detail: details.detail,
                status: details.status,
                title: details.title,
                traceId: details.traceId,
                type: details.type,
              }
            : null,
          url: error.url,
        };
    }
}
