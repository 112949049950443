import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { PopoverModule, TooltipsModule } from "@progress/kendo-angular-tooltip";
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { MenusModule } from '@progress/kendo-angular-menu';
import { SdxControlDirective } from './directives/sdx-control.directive';
import { ShowFeatureDirective } from './directives/show-feature.directive';
import { SidebarActionMarginDirective } from './directives/sidebar-action-margin.directive';
import { UserFotoComponent } from './components/user-foto/user-foto.component';
import { IsPendingPipe } from './pipes/is-pending.pipe';
import { TestIdDirective } from './directives/test-id.directive';
import { TestIdKendoTextboxDirective } from "./directives/test-id-kendo-textbox.directive";
import { KendoStylingTestComponent } from './components/kendo-styling-test/kendo-styling-test.component';
import { ValidationErrorSummaryComponent } from './components/validation-error-summary/validation-error-summary.component';
import { ParseDatePipe } from './pipes/parse-date.pipe';
import { PreventAccessOnFeatureToggleGuard } from './guard/prevent-access-on-feature-toggle-guard';
import { LoadingComponent } from './components/loading/loading.component';
import { ObjectTypeColorResolverService } from './services/object-type-color-resolver.service';
import { KeyMembersComponent } from './components/keymembers/keymembers.component';
import { LogoComponent } from './components/logo/logo.component';
import { FooterComponent } from './components/footer/footer.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { PreventAccessOnPermissionGuard } from './guard/prevent-access-on-permission.guard';
import { ObjectStatusBadgeComponent } from './components/object-status-badge/object-status-badge.component';
import { ObjectStatusResolverService } from './services/object-status-resolver.service';
import { NavigationButtonComponent } from './components/right-clickable-button/navigation-button.component';
import { FormattedTextComponent } from './components/formatted-text/formatted-text.component';

export const KendoModules: Type<unknown>[] = [
  InputsModule,
  LabelModule,
  DropDownsModule,
  DateInputsModule,
  ButtonsModule,
  GridModule,
  ExcelModule,
  DialogsModule,
  IndicatorsModule,
  NavigationModule,
  LayoutModule,
  PopoverModule,
  ToolBarModule,
  MenusModule,
  TooltipsModule,
];

@NgModule({
  declarations: [
    FooterComponent,
    SdxControlDirective,
    ShowFeatureDirective,
    SidebarActionMarginDirective,
    UserFotoComponent,
    IsPendingPipe,
    ParseDatePipe,
    TestIdDirective,
    TestIdKendoTextboxDirective,
    KendoStylingTestComponent,
    ValidationErrorSummaryComponent,
    LoadingComponent,
    KeyMembersComponent,
    LogoComponent,
    UnauthorizedComponent,
    ObjectStatusBadgeComponent,
    NavigationButtonComponent,
    FormattedTextComponent,
  ],
  imports: [...KendoModules, CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    ...KendoModules,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SdxControlDirective,
    ShowFeatureDirective,
    SidebarActionMarginDirective,
    UserFotoComponent,
    IsPendingPipe,
    ParseDatePipe,
    TestIdDirective,
    TestIdKendoTextboxDirective,
    KendoStylingTestComponent,
    ValidationErrorSummaryComponent,
    LoadingComponent,
    KeyMembersComponent,
    LogoComponent,
    FooterComponent,
    UnauthorizedComponent,
    ObjectStatusBadgeComponent,
    NavigationButtonComponent,
  ],
  providers: [PreventAccessOnFeatureToggleGuard, ObjectTypeColorResolverService, PreventAccessOnPermissionGuard, ObjectStatusResolverService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule { }
