import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatestWith, delay, filter, first, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { WelcomeMessageService } from '../welcome-message.service';
import { succeedLogin } from '../../../core/core.actions';
import { selectWelcomeMessageState } from './welcome-message.selector';
import { hideWelcomeMessage } from './welcome-message.actions';

@Injectable()
export class WelcomeMessageEffects {
  public constructor(
    private readonly actions$: Actions,
    private readonly welcomeMessageService: WelcomeMessageService,
    private readonly store: Store,
  ) { }

  public readonly showWelcomeMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(succeedLogin),
        concatLatestFrom(() => this.store.select(selectWelcomeMessageState)),
        filter(([, state]) => {
          const wasNotClosedBefore = this.welcomeMessageService.isCurrentMessage(state.closedMessageId);
          // TODO: Magic symbol - introduce meaningul constant
          const isNotSkipWindow = state.closedMessageId !== '*';
          return wasNotClosedBefore && isNotSkipWindow;
        }),
        combineLatestWith(this.actions$.pipe(ofType(ROUTER_NAVIGATED))),
        first(),
        // wait until page rendered
        delay(500),
        tap(() => this.welcomeMessageService.showMessageWindow()),
      );
    },
    { dispatch: false },
  );

  public readonly hideWelcomeMessage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(hideWelcomeMessage),
        tap(() => this.welcomeMessageService.closeMessageWindow()),
      );
    },
    { dispatch: false },
  );
}
