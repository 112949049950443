import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectFeatureManagerLoadStatus } from '../../core/core.selectors';
import { FeatureFlags, FeatureManager } from '../../core/featureToggle/feature-manager.service';
import { ResourceStatus } from '../state/resource.state';
import { FEATURETOGGLE_CONFIGURATION_TOKEN } from './feature-toggle-configuration-token';

@Injectable()
export class PreventAccessOnFeatureToggleGuard implements CanActivate {
  public constructor(
    @Inject(FEATURETOGGLE_CONFIGURATION_TOKEN) private readonly config: Map<string, FeatureFlags>,
    private readonly router: Router,
    private readonly featureManager: FeatureManager,
    private readonly store: Store,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const pathFromConfig = route.routeConfig?.path;
    const featureName = this.getRouteBasedFeatureName(pathFromConfig);
    return this.store.select(selectFeatureManagerLoadStatus).pipe(
      filter((s) => s !== ResourceStatus.pending),
      map(() => this.isFlagEnabled(featureName)),
    );
  }

  public isFlagEnabled(featureName: FeatureFlags): boolean | UrlTree {
    if (!this.featureManager.isEnabled(featureName)) {
      return this.router.parseUrl(`/unauthorized`);
    }
    return true;
  }

  private getRouteBasedFeatureName(path: string | undefined): FeatureFlags {
    const key: string = path ?? '';
    return this.config.get(key) as FeatureFlags;
  }
}
