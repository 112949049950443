import {
  applyPolyfills,
  defineCustomElements,
} from '@swisscom/sdx/dist/js/webcomponents/loader';

import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PopupModule } from "@progress/kendo-angular-popup";
import { IconModule } from '@progress/kendo-angular-icons';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { reducers, metaReducers } from './core/core.reducers';
import { CoreEffects } from './core/core.effects';
import { SharedModule } from './shared/shared.module';
import { WelcomeMessageModule } from './features/welcome-message/welcome-message.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    CoreModule,
    CoreModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      // TODO: as soon as we stabelize the version and cover with tests we can switch to use checks only in non-prod for performance reasons
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionTypeUniqueness: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([CoreEffects]),
    environment.key === 'Local'
      ? StoreDevtoolsModule.instrument()
      : [],
    NotificationModule,
    IconModule,
    PopupModule,
    WelcomeMessageModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor() {
    applyPolyfills().then(() => {
      defineCustomElements();
    });
  }
}
