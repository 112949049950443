import { DefaultUrlSerializer, RouterStateSnapshot, ActivatedRouteSnapshot, createUrlTreeFromSnapshot } from "@angular/router";
import { MinimalActivatedRouteSnapshot, RouterStateSerializer } from "@ngrx/router-store";
import { isNonNullableGuard } from "../utils/isNonNullableGuard";
import { RouteInfo, RouteStateSnapshot } from "./router.state";

export class RouteInfoSerializer implements RouterStateSerializer<RouteStateSnapshot> {
  // must be same as UrlSerializer in RouterModule
  private static readonly defaultParser = new DefaultUrlSerializer();

  public serialize(routerState: RouterStateSnapshot): RouteStateSnapshot {
    let route = routerState.root;
    let params = {};
    while (route.firstChild) {
      route = route.firstChild;
      params = {
        ...params,
        ...route.params,
      };
    }

    const url = routerState.url;
    const queryParams = routerState.root.queryParams;
    const info = this.getInfoList(routerState.root);
    const snapshot = this.serializeRoute(routerState.root);

    return { url, params, queryParams, root: snapshot, info };
  }

  /**
   * Returns a chain of route infos from provided (active) route to the root route
   * Provide route's data and full url from root with query param
   * @param snapshot
   * @returns RouteInfo[]
   */
  public getInfoList(snapshot: ActivatedRouteSnapshot | null | undefined): ArrayLike<RouteInfo> {
    if (!(snapshot instanceof ActivatedRouteSnapshot)) {
      return [];
    }

    const treeUrl = this.serializeSnapshot(snapshot);
    const nodes = [];

    let route: ActivatedRouteSnapshot | null = snapshot.root || snapshot;
    let prevSegmentEndPos = 0;

    while (isNonNullableGuard(route)) {
      // skip routes with empty path (they are used only to declare children)
      const isHasPath = route.url.length > 0;
      if (isHasPath) {
        const routeData = route.data;
        const segmentEndPos = treeUrl.indexOf('/', prevSegmentEndPos + 1);
        const isLastSegment = segmentEndPos === -1;
        const routeUrl = isLastSegment ? treeUrl : treeUrl.substring(0, segmentEndPos);

        nodes.push({ routeData, routeUrl });
        prevSegmentEndPos = segmentEndPos;
      }
      route = route.firstChild;
    }

    return nodes;
  }

  /**
   * Serialize snapshot to url string.
   * Is it final (full) route, and has query params - add query params to the result url
   * @param route snapshot to serialize
   * @returns
   */
  public serializeSnapshot(route: ActivatedRouteSnapshot): string {
    const urlTree = createUrlTreeFromSnapshot(route, [], route.queryParams);

    return RouteInfoSerializer.defaultParser.serialize(urlTree);
  }

  private serializeRoute(route: ActivatedRouteSnapshot): MinimalActivatedRouteSnapshot {
    const children = route.children.map((c) => this.serializeRoute(c));
    return {
      title: route.title,
      params: route.params,
      data: route.data,
      url: route.url,
      outlet: route.outlet,
      routeConfig: route.routeConfig
        ? {
          path: route.routeConfig.path,
          pathMatch: route.routeConfig.pathMatch,
          redirectTo: route.routeConfig.redirectTo,
          outlet: route.routeConfig.outlet,
        }
        : null,
      queryParams: route.queryParams,
      fragment: route.fragment,
      firstChild: children[0],
      children,
    };
  }
}
