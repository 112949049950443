import { Injectable } from '@angular/core';
import { AuthConfig, OAuthEvent, OAuthService, ValidationHandler } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../abstractions/auth.service';
import { ClaimsIdentity } from '../models/claims-identity';

// TODO: improve with caching and state management

@Injectable()
export class OidcService implements AuthService {

    public constructor(
        private readonly oauthService: OAuthService,
        private readonly validationHandler: ValidationHandler) { }

    public get hasValidTokens(): boolean {
        return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
    }

    public readonly tokenExpired$: Observable<OAuthEvent>
        = this.oauthService.events.pipe(
            filter((e) => e.type === 'token_expires'),
        );

    public async initLogin(config: AuthConfig): Promise<void> {
        this.oauthService.configure(config);
        this.oauthService.tokenValidationHandler = this.validationHandler;
        this.oauthService.requestAccessToken = true;
        this.oauthService.silentRefreshRedirectUri = window.location.origin + "/silent-refresh.html";

        await this.oauthService.loadDiscoveryDocument();
    }

    public processLogin(): void {
        this.oauthService.initImplicitFlow();
    }

    public async processLoginCallback(): Promise<boolean> {
        return this.hasValidTokens || this.oauthService.tryLoginImplicitFlow();
    }

    public get identityClaims(): ClaimsIdentity {
        return this.oauthService.getIdentityClaims() as ClaimsIdentity;
    }

    public getAccessToken(): string {
        return this.oauthService.getAccessToken();
    }

    public processSilentRefresh(): Promise<OAuthEvent> {
        return this.oauthService.silentRefresh();
    }
}
