import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import flagsmith from 'flagsmith';
import { environment } from '../../../environments/environment';

export type FeatureFlags =
  | 'test_feature_toggle'
  | 'devops_portal_link'
  | 'object_create_feature'
  | 'object_edit_feature'
  | 'my_favorites_feature'
  | 'reports_feature';

@Injectable({
  providedIn: 'root',
})
export class FeatureManager {
  public init(apiUrl: string, environmentKey: string, userName: string): Observable<unknown> {
    return from(
      flagsmith.init({
        api: apiUrl,
        environmentID: environmentKey,
        headers: {
          'X-API-KEY': environment.proxyAPIKey,
        },
        cacheFlags: false,
        identity: userName,
      }),
    );
  }

  public logout(): Observable<unknown> {
    return from(flagsmith.logout());
  }

  public isEnabled(feature: FeatureFlags): boolean {
    return flagsmith.hasFeature(feature);
  }
}
