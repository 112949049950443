import { ActionReducer, Action, INIT, UPDATE } from '@ngrx/store';
import { StorageWrapper } from '../abstractions/storage-wrapper';

export const persistantStorageMetaReducerFactory = <S, A extends Action = Action>(
  localStorageKey: string,
  storageWrapper: StorageWrapper,
) => {
  return (reducer: ActionReducer<S, A>): ActionReducer<S, A> => {
    return (state: S | undefined, action: A): S => {
      // get to the nextState.
      const nextState = reducer(state, action);

      if ([INIT.toString(), UPDATE.toString()].includes(action.type)) {
        const savedState = storageWrapper.get<S>(localStorageKey);
        return { ...nextState, ...savedState };
      }

      // save the next state to the application storage.
      storageWrapper.set(localStorageKey, nextState);

      return nextState;
    };
  };
}
