import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AOG_AUTH_SERVICE_INJECT_TOKEN, AuthService } from '../abstractions/auth.service';
import { UserDto } from '../models/user-dto';

@Injectable()
export class UserService {

    public constructor(
        private readonly client: HttpClient,
        @Inject(AOG_AUTH_SERVICE_INJECT_TOKEN)
        private readonly authService: AuthService) {
    }

    public getUser(employeeNr: string): Observable<UserDto> {
        return this.client.get<UserDto>(`${environment.apiBaseUrl}/user/${employeeNr}`);       
    }

    public getEmployeeId(): string | undefined {
        return this.authService.identityClaims.user_attributes.employee_id[0];
    }
}

