import { Inject, Injectable, NgZone } from "@angular/core";
import { StorageWrapper } from "../abstractions/storage-wrapper";
import { LOCAL_STORAGE_INJECT_TOKEN } from "../abstractions/browser-api";
import { StorageCache } from "../abstractions/storage-cache";

@Injectable()
export class LocalStorageService implements StorageWrapper {
    private readonly storageKey = "anyorg_storage"

    public constructor(
        private readonly zone: NgZone,
        @Inject(LOCAL_STORAGE_INJECT_TOKEN)
        private readonly localStorageWrapper: Storage,
    ) {
    }
    
    public get<T>(key: string): T | null {
        const storage = this.deserializeStorage<T>();
        if(storage) {
            return storage[key] ?? null;
        } else {
            return null;
        }
    }

    public remove(key: string): void {
        this.updateStorage(key, null);
    }

    public set<T>(key: string, value: T): void {
        this.updateStorage(key, value);
    }

    private deserializeStorage<T>(): StorageCache<T> | null {
        const serializedStorage = this.localStorageWrapper.getItem(this.storageKey);
        if(!serializedStorage) {
            return null;
        }
        const storage: StorageCache<T> = { };
        Object.assign(storage, JSON.parse(serializedStorage))
        return storage;
    }

    private updateStorage<T>(key: string, value: T | null): void {
        const storage = this.deserializeStorage<T>() ?? { };
        storage[key] = value;
        this.persistOutsideNgZone (() => {
            this.localStorageWrapper.setItem(this.storageKey, JSON.stringify(storage));
        });
    }

    private persistOutsideNgZone(callback: () => void): void {
        // Debounce invocations of the given callback outside of the Angular zone.
        this.zone.runOutsideAngular(
            () => {
                // Even if LocalStorage exists (which is why this Class was
                // instantiated), interacting with it may still lead to runtime errors.
                // --
                // From MDN: If localStorage does exist, there is still no guarantee that
                // localStorage is actually available, as various browsers offer settings
                // that disable localStorage. So a browser may support localStorage, but
                // not make it available to the scripts on the page. For example, Safari
                // browser in Private Browsing mode gives us an empty localStorage object
                // with a quota of zero, effectively making it unusable. Conversely, we
                // might get a legitimate QuotaExceededError, which means that we've used
                // up all available storage space, but storage is actually available.
                try {
                    callback();
                } catch (error) {
                    console.warn("LocalStorageWrapper was unable to write to LocalStorage API.");
                    console.error(error);
                }
            },
        );
    }
}
