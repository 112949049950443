import { InjectionToken } from '@angular/core';
import { AuthConfig, OAuthEvent } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { ClaimsIdentity } from '../models/claims-identity';

export const AOG_AUTH_SERVICE_INJECT_TOKEN
    = new InjectionToken<AuthService>('Authentication service wrapper.')

export interface AuthService {
    hasValidTokens: boolean;

    tokenExpired$: Observable<OAuthEvent>;

    initLogin(config: AuthConfig): Promise<void>;

    /**
     * Setup the application authentication state with the data gotten from the identity provider
     * @returns Promise with the logical result of the operation - is it finished correctly.
     */
    processLogin(): void;

    processLoginCallback(): Promise<boolean>;

    identityClaims: ClaimsIdentity;

    getAccessToken(): string;

    processSilentRefresh(): Promise<OAuthEvent>;
}
