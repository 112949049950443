import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../abstractions/config.service';
import { Configuration } from '../models/configuration';
import { ClientConfigDto } from '../models/client-config-dto';

@Injectable()
export class ExternalConfigService implements ConfigService {

    private readonly client: HttpClient;

    public constructor(
        private readonly store: Store,
        backend: HttpBackend,
    ) {
        // We need to do it manually because of the dependency on it in interceptors
        // Otherwise we will have a circuit: INTERCEPTORS -> ConfigService -> HttpClient -> INTERCEPTORS
        this.client = new HttpClient(backend);
    }

    public getConfig(): Observable<Configuration> {
        return this.client.get<ClientConfigDto>(`${environment.apiBaseUrl}/clientConfig`)
            .pipe(
                map((dto) => {
                    return {
                        // TODO: Move most things to the backend
                        authentication: {
                            issuer: dto.issuer,
                            clientId: dto.clientId,
                            scope: dto.scopesSupported,
                            clearHashAfterLogin: false, // It is ENV agnostic configuration, should not be obtained externally
                            strictDiscoveryDocumentValidation: false, // It is ENV agnostic configuration, should not be obtained externally
                            disableAtHashCheck: true, // It is ENV agnostic configuration, should not be obtained externally,
                            requireHttps: !dto.localIdentityService,
                            skipIssuerCheck: dto.localIdentityService,
                        },
                        resourceServer: {
                            allowedUrls: [
                                dto.userInfoEndpoint,
                            ],
                            sendAccessToken: true, // It is ENV agnostic configuration, should not be obtained externally
                        },
                        featureToggleSettings: dto.featureToggleSettings,
                        devOpsPortalBaseLink: dto.devOpsPortalBaseLink,
                    };
                }),
            );
    }
}

