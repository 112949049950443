import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import {
  AOG_PERMISSION_PROVIDER_SERVICE_INJECT_TOKEN,
  PermissionProvider,
} from '../../core/abstractions/permission-provider';
import { Permission } from '../../core/core.state';
import filterNullOrUndefined from '../../core/operators/filterNullOrUndefined';
import { getRouteParam } from '../../core/utils/getRouteParam';

@Injectable()
export class PreventAccessOnPermissionGuard implements CanActivate {
  public constructor(
    private readonly router: Router,
    @Inject(AOG_PERMISSION_PROVIDER_SERVICE_INJECT_TOKEN)
    private readonly permissionProvider: PermissionProvider,
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const permission = route.data['permission'] as Permission;
    const objectIdParamName = route.data['objectIdParamName'] as string;
    const objectId = getRouteParam<string>(route.params, objectIdParamName);
    return this.permissionProvider.isPeopleGroupActionAuthorizedByObjectId(objectId, permission).pipe(
      filterNullOrUndefined(),
      map((authorized) => this.isAuthorized(authorized)),
    );
  }

  private isAuthorized(authorized: boolean | null): boolean | UrlTree {
    if (!authorized) {
      return this.router.parseUrl(`/unauthorized`);
    }
    return true;
  }
}
