import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

export const WELCOME_MESSAGE_STATE_FEATURE_KEY = 'welcomeMessage';

export const WELCOME_MESSAGE_LOCAL_STORAGE_KEY =
  new InjectionToken<string[]>('WelcomeMessageStateStorage');

export const WELCOME_MESSAGE_STATE_CONFIG_TOKEN =
  new InjectionToken<ActionReducerMap<WelcomeMessageState>>('welcomeMessageStateReducerToken');

export type WelcomeMessageState = {
  closedMessageId: string;
}
