import { OAuthModuleConfig, OAuthStorage, ValidationHandler } from 'angular-oauth2-oidc';
import { NgZone, Provider } from '@angular/core';
import { AOG_AUTH_SERVICE_INJECT_TOKEN } from './abstractions/auth.service';
import { AOG_CONFIG_SERVICE_INJECT_TOKEN } from './abstractions/config.service';
import { AuthModuleConfigService } from './services/auth-module-config.service';
import { JwksValidationService } from './services/jwks-validation.service';
import { ExternalConfigService } from './services/external-config.service';
import { OidcService } from './services/oidc.service';
import {
    LOCAL_STORAGE_INJECT_TOKEN,
    SESSION_STORAGE_INJECT_TOKEN,
    WINDOW_WRAPPER_INJECT_TOKEN,
} from './abstractions/browser-api';
import { SessionStorageService } from './services/session-storage.service';
import { LocalStorageService } from './services/local-storage.service';

// Here is a list of services placed into the file to be able to replace
// them at the build time in convinient way (by one replacement).

export const serviceProviders: Provider[] = [
    { provide: AOG_CONFIG_SERVICE_INJECT_TOKEN, useClass: ExternalConfigService },
    { provide: AOG_AUTH_SERVICE_INJECT_TOKEN, useClass: OidcService },
    { provide: OAuthModuleConfig, useClass: AuthModuleConfigService },
    { provide: ValidationHandler, useClass: JwksValidationService },
    {
        provide: OAuthStorage,
        useFactory: (storage: Storage): OAuthStorage => storage,
        deps: [SESSION_STORAGE_INJECT_TOKEN],
    },
    {
        provide: SessionStorageService,
        useFactory: (
            zone: NgZone,
            window: Window,
            storage: Storage,
        ): SessionStorageService => new SessionStorageService(zone, window, storage),
        deps: [
            NgZone,
            WINDOW_WRAPPER_INJECT_TOKEN,
            SESSION_STORAGE_INJECT_TOKEN,
        ],
    },
    {
        provide: LocalStorageService,
        useFactory: (
            zone: NgZone,
            storage: Storage,
        ): LocalStorageService => new LocalStorageService(zone, storage),
        deps: [
            NgZone,
            LOCAL_STORAGE_INJECT_TOKEN,
        ],
    },
]
