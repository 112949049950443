import { Data, Params } from "@angular/router";
import { MinimalRouterStateSnapshot } from "@ngrx/router-store";
import { RootState } from "../core.state";

export const ROUTER_STATE_KEY: keyof RootState = 'router';

export interface RouteStateSnapshot extends MinimalRouterStateSnapshot {
  url: string;
  params: Params;
  queryParams: Params;

  /**
   * Additional info to use in application logic.
   * For example, in breadcrumbs or drawers
   */
  info: ArrayLike<RouteInfo>;
}

export interface RouteInfo {
  routeData: Data;
  routeUrl: string;
}
