import { Injectable } from "@angular/core";
import { ObjectState, ObjectStates } from "../components/object-status-badge/object-status-badge.component";

import {isDateAfter, isDateBefore} from "../../core/utils/date-calculations";

@Injectable()
export class ObjectStatusResolverService {
    public getObjectStateFromDates(validFrom: Date, validTo: Date): ObjectState {
        if (isDateAfter(validFrom, Date.now())) {
            return ObjectStates.future;
        }
        if (isDateBefore(validTo, Date.now())) {
            return ObjectStates.past;
        }
        return ObjectStates.active;
    }
}
