import { RouterReducerState } from '@ngrx/router-store';
import { EntityState } from '@ngrx/entity';
import { ResourceState, ResourceStatus } from '../shared/state/resource.state';
import { Configuration } from './models/configuration';
import { Notification } from './models/notification';
import { RouteStateSnapshot } from './router/router.state';
import { peopleGroupAuthorizationInitialState } from './authorization-entity.adapter';

export type Permission =
  | 'object_create_feature' | 'object_edit_feature' | 'NONE'

export interface AppState {
  clientConfig: ResourceState<Configuration | null>;
  user: ResourceState<User | null>;
  notifications: Notification[];
  featureManager: ResourceState<boolean>;
  peopleGroupAuthorization: EntityState<PeopleGroupAuthorization>;
}

export const initialAppState: AppState = {
  clientConfig: {
    data: null,
    status: ResourceStatus.none,
    error: null,
  },
  user: {
    data: null,
    status: ResourceStatus.none,
    error: null,
  },
  notifications: [],
  featureManager: {
    data: false,
    status: ResourceStatus.none,
    error: null,
  },
  peopleGroupAuthorization: peopleGroupAuthorizationInitialState,
};

export interface RootState {
  app: AppState;
  router: RouterReducerState<RouteStateSnapshot>;
}

export interface User {
  login: string
  name: string;
  employeeNr: string;
  organization: string;
}

export interface PeopleGroupAuthorization {
  id: string,
  permissions: Permission[];
}

