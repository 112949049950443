import { createAction, props } from '@ngrx/store';
import { AuthConfig } from 'angular-oauth2-oidc';
import { PeopleGroupAuthorization, User } from './core.state';
import { Configuration } from './models/configuration';
import { Notification } from './models/notification';
import { SerializableHttpErrorResponse } from './abstractions/error-actions-handler';

export const loadClientConfig = createAction('[App] Load ClientConfig');

export const loadClientConfigSucceeded = createAction(
  '[App] Load ClientConfig Succeeded',
  props<{ config: Configuration }>(),
);

export const loadFeatureManager = createAction('[App] Load FeatureManager');
export const loadFeatureManagerSucceeded = createAction('[App] Load FeatureManager Succeeded');

// TODO: move authentication to separate place
export const initLogin = createAction('[App] Init Login', props<{ config: AuthConfig }>());
export const processLogin = createAction('[App] Process Login');
export const processLoginCallback = createAction('[App] Process Login callback');
export const succeedLogin = createAction('[App] Succeed Login');
export const failLogin = createAction('[App] Fail Login');

export const loadUserDataSucceeded = createAction('[App] Load loadUserConfig Succeeded', props<{ user: User }>());

export const faileUserDataRequest = createAction(
  '[App] Fail User data request',
  props<{ error: SerializableHttpErrorResponse }>(),
);

export const failGenericRequest = createAction(
  '[App] Fail HTTP request',
  props<{ error: SerializableHttpErrorResponse }>(),
);

export const failConfigurationLoading = createAction(
  '[App] Fail ClientConfig loading',
  props<{ error: SerializableHttpErrorResponse }>(),
);

// Notification Actions
export const addNotification = createAction('[App] Add Notification', props<{ notification: Notification }>());

export const removeNotification = createAction('[App] Remove Notifiaction', props<{ id: string }>());

export const redirectToNotFound = createAction('[App] Redirect To NotFound');

export const redirectToError = createAction('[App] Redirect To Error');

export const goTo = createAction('[App] Redirect To Route', props<{ route: string }>());

export const goToOData = createAction(
  '[App] Redirect To Route with Odata Query',
  props<{ route: string; query: string }>(),
);

export const loadPeopleGroupAuthorization = createAction(
  '[App] load people group authorization',
  props<{ objectId: string }>(),
);

export const updatePeopleGroupAuthorization = createAction(
  '[App] update people group authorization',
  props<{ authorization: PeopleGroupAuthorization }>(),
);
