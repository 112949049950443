import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { environment } from '../../../environments/environment';

@Injectable()
export class CorrelationIdHttpInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.indexOf(environment.apiBaseUrl) > -1) {
      request = request.clone({
        setHeaders: {
          // we use the naming convention from the backend correlation id lib
          CorrelationId: uuid(),
        },
      });
    }

    return next.handle(request);
  }
}
