import { Inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ResourceStatus } from 'src/app/shared/state/resource.state';
import { AOG_AUTH_SERVICE_INJECT_TOKEN, AuthService } from '../abstractions/auth.service';
import { selectUserState } from '../core.selectors';

@Injectable()
export class AuthGuard implements CanActivate {
    public constructor(
        @Inject(AOG_AUTH_SERVICE_INJECT_TOKEN)
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly store: Store,
    ) { }

    public canActivate(
        _route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return this.store.select(selectUserState).pipe(
          filter((u) => u.status === ResourceStatus.failed || u.status === ResourceStatus.succeeded),
          map((u) => u?.data !== null && u?.data !== undefined && this.authService.hasValidTokens),
          tap((canLoad) => console.debug(`You tried to go to ${state.url} and this guard said ${canLoad.toString()}`)),
          map((canLoad) => (canLoad ? canLoad : this.router.parseUrl('/errors/unauthorized'))),
        );
    }
}
