import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { Analytics } from '@angular/fire/analytics';
import { selectIsAppLoading } from './core/core.selectors';
import { loadClientConfig } from './core/core.actions';

@Component({
      selector: 'aog-root',
      templateUrl: './app.component.html',
      styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    private readonly analytics: Analytics | null = inject(Analytics, { optional: true });
    public readonly isLoading$ = this.store.select(selectIsAppLoading);

    public constructor(
        private readonly store: Store,
    ) { }

    public ngOnInit(): void {
        this.initConfig();
        this.enableGoogleAnalytics();
    }

    private initConfig() {
        this.store.dispatch(loadClientConfig());
    }

    private enableGoogleAnalytics() {
        if (this.analytics) {
            this.analytics.app.automaticDataCollectionEnabled = true;
        }
    }
}
