import { Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { environment } from '../../../environments/environment';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';

@Injectable()
export class WelcomeMessageService {

  private readonly currentMessageId = environment.buildVersion;

  private openedWindowRef: DialogRef | null = null;

  public constructor(
    private readonly dialogService: DialogService,
  ) { }

  public isCurrentMessage(messageId: string) {
    return messageId !== this.currentMessageId;
  }

  public showMessageWindow() {
    if (this.openedWindowRef) {
      this.openedWindowRef.close();
      this.openedWindowRef = null;
    }

    const ref = this.dialogService.open({
      content: WelcomeMessageComponent,
    });

    (ref.content.instance as WelcomeMessageComponent).messageId = this.currentMessageId;

    this.openedWindowRef = ref;
  }

  public closeMessageWindow() {
    if (this.openedWindowRef) {
      this.openedWindowRef.close();
      this.openedWindowRef = null;
    }
  }
}
