import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../models/configuration';

export const AOG_CONFIG_SERVICE_INJECT_TOKEN
    = new InjectionToken<ConfigService>('Authentication service wrapper.')

export interface ConfigService {
    getConfig(): Observable<Configuration>;
}
