import { Inject, Injectable } from '@angular/core';
import { BACKEND_DATE_FORMAT_INJECT_TOKEN, DISPLAY_DATE_FORMAT_INJECT_TOKEN } from '../core.module';
import {
  dateTimeFromDate,
  dateTimeFromFormat,
  DateValue,
  isMaxDate,
  MAX_DATE_TIMESTAMP,
  normalizeDate,
} from '../utils/date-calculations';

const UNKNOWN_ERROR_MESSAGE = 'Unknown error';
// Adapter for dates, should incapsulate logic for date/string/number conversion
// Now it hides the usage of the library, but should be switched to native Temporal API
// as soon as it will be released.
// TODO: Switch to temporal API as soon as polyfill will be ready
// TODO: create unit tests
@Injectable()
export class DateFormatService {
  public constructor(
    @Inject(BACKEND_DATE_FORMAT_INJECT_TOKEN)
    private readonly backendDateFormat: string,
    @Inject(DISPLAY_DATE_FORMAT_INJECT_TOKEN)
    private readonly displayDateFormat: string,
  ) {}

  /**
   * Return date as string in backend format
   * @param date
   */
  public formatDate(date: DateValue): string {
    const internalDate = dateTimeFromDate(date);
    if (!internalDate.isValid) {
      throw new Error(
        `Date provided does not conform required format: ${internalDate.invalidExplanation ?? UNKNOWN_ERROR_MESSAGE}`,
      );
    }

    const result = internalDate.toFormat(this.backendDateFormat);
    if (!result) {
      throw new Error('Date provided does not conform required format.');
    }

    return result;
  }

  public formatTimestamp(timestamp: number): string {
    return this.formatDate(timestamp);
  }

  public parseDate(date: string): Date {
    const internalDate = dateTimeFromFormat(date, this.backendDateFormat);
    if (!internalDate.isValid) {
      throw new Error(
        `Date provided does not conform required format: ${internalDate.invalidExplanation ?? UNKNOWN_ERROR_MESSAGE}`,
      );
    }

    return internalDate.toJSDate();
  }

  public parseTimestampFromString(value: string): number | null {
    if (!value) {
      // only valid case to not throw errors - empty input
      return null;
    }

    const internalDate = dateTimeFromFormat(value, this.backendDateFormat);

    if (!internalDate.isValid) {
      throw new Error(
        `Date provided does not conform required format: ${internalDate.invalidExplanation ?? UNKNOWN_ERROR_MESSAGE}`,
      );
    }

    return internalDate.toMillis();
  }

  public parseTimestamp(value: number | null | undefined): Date | null {
    if (!value) {
      // only valid case to not throw errors - empty input
      return null;
    }

    if (isMaxDate(value)) {
      return null;
    }

    const internalDate = new Date(value);

    return normalizeDate(internalDate);
  }

  public formatDateUI(dateString: string): string {
    const date = new Date(dateString);

    if (date.getFullYear() === 9999) {
      return '';
    }

    return this.formatDate(date);
  }

  public parseTimeStampFromDate(date: Date | null): number {
    return Number(this.parseTimestampFromString(this.formatDate(date ?? new Date(MAX_DATE_TIMESTAMP))));
  }

  /* Timestamp from datepicker contains date and time both. This fetches only date from timestamp and then convert to respective timestamp*/
  public getTimeStampForOnlyDate(timestampInput: number | null): number {
    return this.parseTimeStampFromDate(this.parseTimestamp(timestampInput));
  }

  public formatDateToFrontendFormat(date?: DateValue): string {
    if (!date) {
      return '';
    }
    if (isMaxDate(date)) {
      return '';
    }
    const dateTime = dateTimeFromDate(date);
    return dateTime.toFormat(this.displayDateFormat);
  }
}
