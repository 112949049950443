import { SerializableHttpErrorResponse } from '../../core/abstractions/error-actions-handler';

export enum ResourceStatus {
  none = 'NONE',
  succeeded = 'SUCCEEDED',
  failed = 'FAILED',
  pending = 'PENDING',
  pendingDelete = 'PENDING_DELETE',
}

export interface ErrorMessage {
  [index: string]: string[];
}

export interface RequestError {
  errors: ErrorMessage | null;
  caption?: string;
  status: number;
}

export interface ResourceState<T> {
  data: T;
  error: RequestError | null;
  status: ResourceStatus;
}

export const updateFromErrorResponse = <T>(
  state: ResourceState<T>,
  error: SerializableHttpErrorResponse,
): ResourceState<T> => ({
  ...state,
  status: ResourceStatus.failed,
  error: {
    status: error.status,
    caption: error.statusText,
    errors: getErrorMessage(error),
  },
});

export const isPending = <T>(state: ResourceState<T>): boolean => state.status === ResourceStatus.pending;

export const getErrorMessage = (error: SerializableHttpErrorResponse): ErrorMessage | null => {
  if (error.error && !isErrorMessage(error.error)) {
    throw new Error(`Error response has unknown format. `);
  }
  return error.error;
};

export const errorMessageArrayToString = (error: string[]): string => {
  const errDelimeter = '\r\n';

  return error.join(errDelimeter);
};

export const errorMessageToString = (error: ErrorMessage | null): string => {
  if (error === null) {
    return '';
  }

  const subErrorDelimeter = '\r\n  ';

  return errorMessageArrayToString(
    Object.entries(error).map(([key, messages]): string => {
      const isHaveTitle = isNaN(Number(key));
      const combinedMessages = messages.join(subErrorDelimeter);

      if (isHaveTitle) {
        return `${key}: ${combinedMessages}`;
      } else {
        return combinedMessages;
      }
    }),
  );
};

const isStringArray = (value: unknown): value is string[] => {
  if (!Array.isArray(value)) {
    return false;
  }

  return !value.some((v) => typeof v !== 'string');
};

const isErrorMessage = (error: unknown): error is ErrorMessage => {
  if (typeof error !== 'object') {
    throw new Error(`Contract error: Error response is not an object.`);
  }

  const errorObj = error as Record<string, unknown>;

  return !Object.values(errorObj).some((v) => !isStringArray(v));
};
