/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, from } from 'rxjs';
import { exhaustMap, filter, map, switchMap, tap } from 'rxjs/operators';
import { Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { routerRequestAction } from '@ngrx/router-store';
import { environment } from '../../environments/environment';
import { FeatureManager } from './featureToggle/feature-manager.service';
import { AOG_AUTH_SERVICE_INJECT_TOKEN, AuthService } from './abstractions/auth.service';
import { AOG_CONFIG_SERVICE_INJECT_TOKEN, ConfigService } from './abstractions/config.service';
import { UserService } from './services/user.service';
import filterNullOrUndefined from './operators/filterNullOrUndefined';

import {
  failGenericRequest,
  loadClientConfig,
  failConfigurationLoading,
  loadClientConfigSucceeded,
  loadUserDataSucceeded,
  faileUserDataRequest,
  processLogin,
  processLoginCallback,
  succeedLogin,
  failLogin,
  redirectToNotFound,
  redirectToError,
  goTo,
  loadFeatureManager,
  loadFeatureManagerSucceeded,
  goToOData,
  initLogin,
  loadPeopleGroupAuthorization,
  updatePeopleGroupAuthorization,
} from './core.actions';
import { HttpErrorEventDispatcherService } from './services/http-error-event-dispatcher.service';
import { HttpRequestPredicates } from './http-request-predicates';
import { selectRouterState } from './router/router.state.selectors';
import { selectClientConfig, selectIsAuthenticated } from './core.selectors';
import { OidcRedirectUriEnrich } from './services/oidc-redirect-uri-enrich.service';
import { AuthorizationService } from './services/authorization.service';

@Injectable()
export class CoreEffects {
  public readonly goTo$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(goTo),
        tap((route) => {
          this.router.navigateByUrl(route.route);
        }),
      );
    },
    { dispatch: false },
  );

  //TODO: Create route using commands instead of using directly with navigat
  public readonly goToOData$: Params = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(goToOData),
        tap((params) => {
          this.router.navigate([params.route], { queryParams: { q: params.query } });
        }),
      );
    },
    { dispatch: false },
  );

  public readonly loadClientConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadClientConfig),
      switchMap(() => this.configService.getConfig()),
      map((dto) => loadClientConfigSucceeded({ config: dto })),
    );
  });

  public readonly loadFeatureManager$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(succeedLogin),
      map(() => loadFeatureManager()),
    );
  });

  public readonly initFeatureManager$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFeatureManager),
      concatLatestFrom(() => this.store.select(selectClientConfig)),
      map(([, config]) => config?.featureToggleSettings),
      filterNullOrUndefined(),
      switchMap((featureToggleSettings) => {
        const userName = this.authService.identityClaims.user_name;

        return this.featureManager.init(featureToggleSettings.apiUrl, featureToggleSettings.environmentKey, userName);
      }),
      map(() => loadFeatureManagerSucceeded()),
    );
  });

  public readonly initLoginOnLoadConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadClientConfigSucceeded),
      map((action) => {
        const config = this.redirectUriEnrich.enrichConfig(action.config.authentication);
        return initLogin({ config });
      }),
    );
  });

  public readonly initLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initLogin),
      switchMap((action) => from(this.authService.initLogin(action.config))),
      exhaustMap(() => from(this.authService.processLoginCallback())),
      map((result) => {
        if (result) {
          return processLoginCallback();
        } else {
          return processLogin();
        }
      }),
    );
  });

  public readonly processLoginCallback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(processLoginCallback),
      map(() => {
        if (this.authService.hasValidTokens) {
          return succeedLogin();
        } else {
          return failLogin();
        }
      }),
    );
  });

  public readonly clearUrlHash$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(succeedLogin),
        concatLatestFrom(() => this.store.select(selectRouterState)),
        map(([, route]) => route?.state.info),
        filterNullOrUndefined(),
        map((routeInfo) => {
          const info = routeInfo;
          if (!Array.isArray(routeInfo)) {
            return null;
          }
          const fullRoute = info[info.length - 1];
          return fullRoute?.routeUrl;
        }),
        filterNullOrUndefined(),
        tap((url) => {
          this.router.navigateByUrl(url, { replaceUrl: true });
        }),
      );
    },
    { dispatch: false },
  );

  public readonly processLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(processLogin),
        tap(() => this.authService.processLogin()),
      );
    },
    { dispatch: false },
  );

  public readonly loadUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(succeedLogin),
      map(() => this.userService.getEmployeeId()),
      filterNullOrUndefined(),
      switchMap((employee_id) => this.userService.getUser(employee_id)),
      map((dto) => loadUserDataSucceeded({ user: dto })),
    );
  });

  public readonly refreshToken$ = createEffect(
    () => {
      return this.authService.tokenExpired$.pipe(
        tap(() => {
          this.authService.processSilentRefresh();
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * This effect takes care of generic requests errors when {@link failGenericRequest} occurs.
   * There should be control over places where the action fires,
   * and, in most cases, more precise actions (from features modules) should be used instead.
   */
  public readonly failGenericRequest$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(failGenericRequest),
        tap((action) => {
          const error = action.error;
          console.debug(error);
          switch (error.status) {
            case 400:
              // Handle Bad requests
              console.info(`Request failed with status '${error.statusText}'. Details: ${error.error?.detail}.`);
              break;
            case 401:
              // Handle authentication issues
              console.info(`Request was not authenticated correctly. Details: ${error.error?.detail}.`);
              break;
            case 403:
              // Handle permission issues
              console.info(`Request was not allowed for current login. Details: ${error.error?.detail}.`);
              break;
            default:
              // Generic handler
              break;
          }
        }),
      );
    },
    {
      dispatch: false,
    },
  );

  public readonly redirectToNotFound$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(redirectToNotFound),
        tap(() => {
          this.router.navigate(['/not-found']);
        }),
      );
    },
    { dispatch: false },
  );

  public readonly redirectToError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(redirectToError),
        tap(() => {
          this.router.navigate(['/error']);
        }),
      );
    },
    { dispatch: false },
  );

  // authorization

  public readonly updatePeopleGroupAuthorization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPeopleGroupAuthorization),
      switchMap((action) => this.authorizationService.getPeopleGroupAuthorization(action.objectId)),
      map((dto) => updatePeopleGroupAuthorization({ authorization: { id: dto.id, permissions: dto.permissions } })),
    );
  });

  public readonly loadPeopleGrouoAuthorization$ = createEffect(() => {
    const isAuthenticated$ = this.store
      .select(selectIsAuthenticated)
      .pipe(filter((isAuthenticated) => !!isAuthenticated));
    const navigatedToObjectWithId$ = this.actions$.pipe(
      ofType(routerRequestAction),
      map((action) => action.payload.event.url),
      filter((url) => this.isAuthorizationRequestRequired(url)),
      map((url) => this.getObjectId(url)),
      filterNullOrUndefined(),
    );

    return combineLatest([isAuthenticated$, navigatedToObjectWithId$]).pipe(
      map((result) => loadPeopleGroupAuthorization({ objectId: result[1] })),
    );
  });

  private getObjectId(url: string): string | null {
    const objectIdRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
    const arrObjectId = url.match(objectIdRegex);
    if (arrObjectId === null || arrObjectId.length === 0) {
      return null;
    }
    return arrObjectId[0];
  }

  private isAuthorizationRequestRequired(url: string): boolean {
    if (url === null) {
      return false;
    }

    return url.indexOf('people-group-overview') > -1 || url.indexOf('operational-structure') > -1;
  }

  public constructor(
    private readonly actions$: Actions,
    @Inject(AOG_CONFIG_SERVICE_INJECT_TOKEN)
    private readonly configService: ConfigService,
    @Inject(AOG_AUTH_SERVICE_INJECT_TOKEN)
    private readonly authService: AuthService,
    private readonly featureManager: FeatureManager,
    private readonly userService: UserService,
    private readonly authorizationService: AuthorizationService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly redirectUriEnrich: OidcRedirectUriEnrich,
    httpErrorHandler: HttpErrorEventDispatcherService,
  ) {
    httpErrorHandler.registerSingleErrorHandler({
      predicate: HttpRequestPredicates.combine(
        HttpRequestPredicates.methodExact('GET'),
        HttpRequestPredicates.urlStartsWith(`${environment.apiBaseUrl}/user`),
      ),
      action: (error) => faileUserDataRequest({ error }),
    });

    httpErrorHandler.registerSingleErrorHandler({
      predicate: HttpRequestPredicates.methodAndUrlExact({
        url: `${environment.apiBaseUrl}/clientConfig`,
        method: 'GET',
      }),
      action: (error) => failConfigurationLoading({ error }),
    });
  }
}
