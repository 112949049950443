import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment";
import { Permission } from "../core.state";

export interface PeopleGroupAuthorizationDto {
    id: string;
    permissions: Permission[];
  }

@Injectable()
export class AuthorizationService {

    public constructor(
        private readonly client: HttpClient,
        private readonly store: Store) {
    }

    public getPeopleGroupAuthorization(objectId: string): Observable<PeopleGroupAuthorizationDto> {
        return this.client.get<PeopleGroupAuthorizationDto>(`${environment.apiBaseUrl}/object/${objectId}/permissions`);       
    }
}
