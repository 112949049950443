import { Inject, Injectable, NgZone } from "@angular/core";
import { StorageCache } from "../abstractions/storage-cache";
import { StorageWrapper } from "../abstractions/storage-wrapper";
import {
    SESSION_STORAGE_INJECT_TOKEN,
    WINDOW_WRAPPER_INJECT_TOKEN,
} from "../abstractions/browser-api";

Injectable()
export class SessionStorageService implements StorageWrapper {
    private readonly debounceDuration: number;
    private readonly storageKey = "anyorg_storage"
    private readonly cache: StorageCache = Object.create(null) as StorageCache;
    private timerID: number;

    public constructor(
        private readonly zone: NgZone,
        @Inject(WINDOW_WRAPPER_INJECT_TOKEN)
        private readonly window: Window,
        @Inject(SESSION_STORAGE_INJECT_TOKEN)
        private readonly sessionStorageWrapper: Storage,
    ) {
        this.zone = zone;
        // The Debounce duration is the trade-off between processing and consistency.
        // Since the SessionStorage API is synchronous, we don't necessarily want to
        // write to it whenever the cache is updated. Instead, we'll use a timerID to reach
        // a moment of inactivity; and then, serialize and persist the data.
        this.debounceDuration = 1000; // 1-second.
        this.timerID = 0;

        // NOTE: Since the SessionStorage API is browser-TAB-specific, we can read the
        // persisted data into memory on load; and, then use the in-memory cache as a
        // buffer in order to cut-down on synchronous processing.
        this.loadFromCache();
    }

    public get<T>(key: string): T | null {
        const value = this.cache[key];
        if (value) {
            return value as T
        } else {
            return null;
        }
    }

    public remove(key: string): void {
        if (key in this.cache) {
            delete (this.cache[key]);
            this.persistToCacheOutsideNgZone();
        }
    }

    public set(key: string, value: unknown): void {
        this.cache[key] = value;
        this.persistToCacheOutsideNgZone();
    }

    private debounceCallback(cb: () => void) {
        this.window.clearTimeout(this.timerID);
        this.timerID = this.window.setTimeout(
            () => {
                this.timerID = 0;
                cb();
            },
            this.debounceDuration,
        );
    }

    private debounceOutsideNgZone(callback: () => void): void {
        // Debounce invocations of the given callback outside of the Angular zone.
        this.zone.runOutsideAngular(() => this.debounceCallback(callback));
    }

    // Loads the browser storage payload into the internal cache so that we don't need
    // to read from the storage whenever the .get() method is called.
    private loadFromCache(): void {
        try {
            const serializedCache = this.sessionStorageWrapper.getItem(this.storageKey);
            if (serializedCache) {
                Object.assign(this.cache, JSON.parse(serializedCache));
            }
        } catch (error) {
            console.warn("SessionStorageWrapper was unable to read from SessionStorage API.");
            console.error(error);
        }
    }

    private persistToCache(): void {
        console.warn("Flushing to SessionStorage API.");
        // Even if SessionStorage exists (which is why this Class was
        // instantiated), interacting with it may still lead to runtime errors.
        // --
        // From MDN: If localStorage does exist, there is still no guarantee that
        // localStorage is actually available, as various browsers offer settings
        // that disable localStorage. So a browser may support localStorage, but
        // not make it available to the scripts on the page. For example, Safari
        // browser in Private Browsing mode gives us an empty localStorage object
        // with a quota of zero, effectively making it unusable. Conversely, we
        // might get a legitimate QuotaExceededError, which means that we've used
        // up all available storage space, but storage is actually available.
        try {
            this.sessionStorageWrapper.setItem(this.storageKey, JSON.stringify(this.cache));
        } catch (error) {
            console.warn("SessionStorageWrapper was unable to write to SessionStorage API.");
            console.error(error);
        }
    }

    // I serialize and persist the cache to the SessionStorage, using debouncing.
    private persistToCacheOutsideNgZone(): void {

        // Since we don't want a change-detection digest to run as part of our internal
        // timer (we have no view-models that will change in response to this action),
        // let's wire-it-up outside of the core Angular Zone.
        this.debounceOutsideNgZone(() => this.persistToCache());
    }
}
