import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";
import { Permission } from "../core.state";

export const AOG_PERMISSION_PROVIDER_SERVICE_INJECT_TOKEN
    = new InjectionToken<PermissionProvider>('Permission provider service wrapper.')

export interface PermissionProvider {
   isPeopleGroupActionAuthorizedByObjectId(objectId: string | null, requiredPermission: Permission): Observable<boolean | null>;
}
