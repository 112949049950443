import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig } from 'angular-oauth2-oidc';
import { WINDOW_WRAPPER_INJECT_TOKEN } from '../abstractions/browser-api';

@Injectable()
export class OidcRedirectUriEnrich {
    public constructor(
        private readonly router: Router,
        @Inject(WINDOW_WRAPPER_INJECT_TOKEN)
        private readonly window: Window,
    ) { }

    public enrichConfig(authenticationConfig: AuthConfig): AuthConfig {
        // As we process the logic in the early stages,
        // Routing could be not ready yet in the store,
        // but we need to decide what is our redirect page.
        // To do so we have to use router here.
        const navigation = this.router.getCurrentNavigation();

        // fallback in case of navigation missing
        let url = "/";
        if (navigation) {
            const urlTree = navigation.finalUrl ?? navigation.extractedUrl;
            url = urlTree.toString();
        }

        const fullUrl = new URL(url, this.window.location.origin);
        const config = {
            ...authenticationConfig,
            redirectUri: fullUrl.toString(),
        }

        return config;
    }
}
