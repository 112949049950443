import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { hideWelcomeMessage } from '../state/welcome-message.actions';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'aog-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeMessageComponent {
  @Input() public messageId = '';

  public constructor(
    public readonly store: Store,
  ) { }

  public get appVersion(): string {
    return environment.buildVersion;
  }

  public onClose() {
    this.store.dispatch(hideWelcomeMessage({ messageId: this.messageId }));
  }
}
