import { HttpRequest } from '@angular/common/http';
import { ErrorAction, ErrorActionsHandler } from '../abstractions/error-actions-handler';

export const httpMethods = ['DELETE', 'GET', 'HEAD', 'JSONP', 'OPTIONS', 'POST', 'PATCH'] as const;

export type HttpMethod = typeof httpMethods[number]

export class HttpErrorEventDispatcherService {
    public getErrorHandler(request: HttpRequest<unknown>): ErrorAction[] {
        return this.errorHandlers
            .filter((h) => h.predicate(request))
            .map((h) => h.action);
    }

    private readonly errorHandlers: ErrorActionsHandler[] = [];

    public registerSingleErrorHandler(handler: ErrorActionsHandler): void {
        this.errorHandlers.push(handler);
    }
}
