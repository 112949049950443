import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[aogTestId]',
})
export class TestIdDirective implements OnInit {
  @Input()
  public aogTestId = '';

  public constructor(private readonly elRef: ElementRef<HTMLElement>) {
  }

  public ngOnInit(): void {
    this.setId(this.elRef.nativeElement, this.aogTestId);
  }

  private setId(element: HTMLElement, id: string) {
    element.setAttribute('test-id', id);
  }
}
