import { Injectable } from '@angular/core';

@Injectable()
export class ObjectTypeColorResolverService {

  private objectTypes: Map<string, string> = new Map([
    ['Value Stream','#06144E'],
    ['Solution Train','#062D78'],
    ['Agile Release Train','#0851DA'],
    ['Team','#11AAFF'],
    ['Lean Cluster','#00847E'],
    ['Shared Service','#5944C6'],
    ['Lean Area','#00ABA9'],
    ['Product Area','#333333'],
    ['Task Force','#E61E64'],
    ['Leadership Team','#11AAFF'],
    ['Portfolio Area','#5944C6'],
    ['Line','#1781E3'],
    ['Portfolio','#8169F9'],
    ['E2E Process Domain','#4E32A8'],
    ['E2E Process','#3281A8'],
    ['Process Cluster','#DAE7FE'],
    ['Process','#C8DBFD'],
    ['Process Variant','#5B92F9'],
    ['Process Map','#648AD8'],
  ]);

  public resolveColorFromObjectType(agileObjectType: string): string {
    const resultColor = this.objectTypes.get(agileObjectType);

    if (resultColor === undefined)
      throw new Error('Invalid Object Type');

    return resultColor;
  }
}

