import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from "@ngrx/store";
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from "../../shared/shared.module";
import { LocalStorageService } from '../../core/services/local-storage.service';
import { WelcomeMessageEffects } from './state/welcome-message.effects';
import {
  WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
  WELCOME_MESSAGE_STATE_CONFIG_TOKEN,
  WELCOME_MESSAGE_STATE_FEATURE_KEY,
} from "./state/welcome-message";
import {
  welcomeMessageRootReducer,
  welcomeMessageStateConfigFactory,
} from "./state/welcome-message.reducer";
import { WelcomeMessageService } from './welcome-message.service';
import { WelcomeMessageComponent } from './welcome-message/welcome-message.component';

@NgModule({
  declarations: [
    WelcomeMessageComponent,
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      WelcomeMessageEffects,
    ]),
    StoreModule.forFeature(
      WELCOME_MESSAGE_STATE_FEATURE_KEY,
      welcomeMessageRootReducer,
      WELCOME_MESSAGE_STATE_CONFIG_TOKEN,
    ),
    SharedModule,
  ],
  providers: [
    WelcomeMessageService,
    {
      provide: WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
      useValue: '__welcomeMessageState_storage__',
    },
    {
      provide: WELCOME_MESSAGE_STATE_CONFIG_TOKEN,
      deps: [
        WELCOME_MESSAGE_LOCAL_STORAGE_KEY,
        LocalStorageService,
      ],
      useFactory: welcomeMessageStateConfigFactory,
    },
  ],
})

export class WelcomeMessageModule { }
