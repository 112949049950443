import { HttpRequest } from '@angular/common/http';
import { unionTypeParserFactory } from './utils/union-type-parser-factory';
import { ApiCallMatchSignature, ApiCallSignature } from './abstractions/api-call-signature';
import { HttpRequestPredicate } from './abstractions/error-actions-handler';
import { HttpMethod, httpMethods } from './services/http-error-event-dispatcher.service';

export class HttpRequestPredicates {

    private static readonly getHttpMethod: (method: string) => HttpMethod | null = unionTypeParserFactory(httpMethods);

    public static urlExact(url: string): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => request.url === url;
    }

    public static urlStartsWith(urlPart: string): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => request.url.startsWith(urlPart);
    }

    public static urlMatch(pattern: RegExp): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => pattern.test(request.url);
    }

    public static combine(...args: HttpRequestPredicate[]): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => args.every((p) => p(request));
    }

    public static methodExact(expected: HttpMethod): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => {
            const method = HttpRequestPredicates.getHttpMethod(request.method);
            return !!method && method === expected;
        };
    }

    public static methodIn(methods: HttpMethod[]): HttpRequestPredicate {
        return (request: HttpRequest<unknown>) => {
            const method = HttpRequestPredicates.getHttpMethod(request.method);
            return !!method && methods.includes(method);
        };
    }

    public static methodAndUrlExact(expected: ApiCallSignature): HttpRequestPredicate {
        return HttpRequestPredicates.combine(
            HttpRequestPredicates.urlExact(expected.url),
            HttpRequestPredicates.methodExact(expected.method),
        );
    }

    public static methodAndUrl(expected: ApiCallSignature): HttpRequestPredicate {
        return HttpRequestPredicates.combine(
            HttpRequestPredicates.urlStartsWith(expected.url),
            HttpRequestPredicates.methodExact(expected.method),
        );
    }

    public static methodAndUrlMatch(expected: ApiCallMatchSignature): HttpRequestPredicate {
        return HttpRequestPredicates.combine(
            HttpRequestPredicates.urlMatch(expected.match),
            HttpRequestPredicates.methodExact(expected.method),
        );
    }
}
