import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AOG_AUTH_SERVICE_INJECT_TOKEN, AuthService } from '../abstractions/auth.service';

@Injectable()
export class RequestAuthenticationInterceptor implements HttpInterceptor {
    public constructor(
        @Inject(AOG_AUTH_SERVICE_INJECT_TOKEN)
        private readonly oauthService: AuthService) {
    }

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.oauthService.getAccessToken();
        if (token) {
            const authHeader = 'Bearer ' + token;
            request = request.clone({
                setHeaders: {
                    Authorization: authHeader,
                },
            });
        }

        return next.handle(request);
    }
}
