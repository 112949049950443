import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isPending, ResourceState } from '../shared/state/resource.state';
import { AppState, User } from './core.state';
import { Configuration } from './models/configuration';

export const selectApp = createFeatureSelector<AppState>('app');

// Client config
export const selectClientConfigState = createSelector(selectApp, (state: AppState) => state.clientConfig);
export const selectClientConfig = createSelector(
    selectClientConfigState,
    (state: ResourceState<Configuration | null>) => state?.data,
);

// Feature flags
export const selectFeatureManager = createSelector(selectApp, (state: AppState) => state.featureManager);
export const selectFeatureManagerLoadStatus = createSelector(
    selectFeatureManager,
    (featureManagerState: ResourceState<boolean>) => featureManagerState.status,
);

export const selectAreFeatureFlagsLoading = createSelector(
    selectFeatureManager,
    (featureManagerState: ResourceState<boolean>) => isPending(featureManagerState),
);

export const selectAreFeatureFlagsLoaded = createSelector(
    selectFeatureManager,
    (featureManagerState: ResourceState<boolean>) => !isPending(featureManagerState) && !!featureManagerState.data,
);

// Authentication
export const selectUserState = createSelector(selectApp, (state: AppState) => state.user);

export const selectUser = createSelector(
    selectUserState,
    (state: ResourceState<User | null>) => state?.data,
);

export const selectUserEmployeeNr = createSelector(selectApp, (state: AppState) => state.user.data?.employeeNr);
export const selectIsAuthenticating = createSelector(
    selectUserState,
    (userState: ResourceState<User | null>) => !!userState && isPending(userState),
);

export const selectIsAuthenticated = createSelector(
    selectUserState,
    (userState: ResourceState<User | null>) => !isPending(userState) && !!userState.data,
);

export const selectNextNotification = createSelector(selectApp, (state: AppState) => {
    if (!state.notifications || state.notifications.length === 0) {
        return null;
    }

    return state.notifications[0];
});

export const selectDevOpsBaseLink = createSelector(selectApp, (state: AppState) => state.clientConfig.data?.devOpsPortalBaseLink);

// App readiness

/**
 * Defines the state when the process of application bootstrapping is going
 * (data have not arrived, but not failed yet too).
 */
export const selectIsAppLoading = createSelector(
    selectAreFeatureFlagsLoading,
    selectIsAuthenticating,
    (areFeatureFlagsLoading, isAuthenticating) => areFeatureFlagsLoading || isAuthenticating,
);

/**
 * Defines the state when the process of application bootstrapping
 * was finished successfully (data arrived).
 */
export const selectAppReadiness = createSelector(
    selectAreFeatureFlagsLoaded,
    selectIsAuthenticated,
    (areFeatureFlagsLoaded, isAuthenticated) => areFeatureFlagsLoaded && isAuthenticated,
);
